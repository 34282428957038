import React from "react"
import Gallery from "react-grid-gallery"
import Layout from "../components/layout"
import SEO from "../components/seo"
import seoContent from "../helpers/seoContent"

const Photography = ({ data }) => {
  const rowHeight = 300
  const { allContentfulAsset } = data
  const allPhotos = allContentfulAsset.edges

  const calculateWidth = (aspectRatio, rowHeight) => rowHeight * aspectRatio

  const loadImages = photos => {
    return photos.map((photo, i) => {
      const { src, aspectRatio } = photo && photo.node && photo.node.fluid
      return {
        src: src,
        thumbnail: src,
        thumbnailHeight: rowHeight,
        thumbnailWidth: calculateWidth(aspectRatio, rowHeight),
      }
    })
  }

  const renderGallery = () => {
    return (
      <Gallery
        id="photo-gallery"
        images={loadImages(allPhotos)}
        rowHeight={rowHeight}
        enableImageSelection={false}
      />
    )
  }
  const { siteDescriptions } = seoContent
  return (
    <Layout>
      <SEO title="Photography" description={siteDescriptions.default} />
      <div id="photos">
        <div className="photos-header">
          <h1>Photography</h1>
        </div>
        <div className="photos-body">{renderGallery()}</div>
      </div>
    </Layout>
  )
}

export default Photography

export const pageQuery = graphql`
  query allPhotos {
    allContentfulAsset {
      edges {
        node {
          fluid {
            src
            aspectRatio
          }
          title
        }
      }
    }
  }
`
